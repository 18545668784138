<template>
  <div class="bidding__wrapper">
    <div class="commom_title">
      <a-row>
        <a-col :span="16">
          <a-row>
            <a-col :span="1">
              <div class="commom_line"></div>
            </a-col>
            <a-col :span="6">视频</a-col>
          </a-row>
        </a-col>
      </a-row>
    </div>
    <div class="info">
      <a-row>
        <a-col :span="24">
          <div class="Title">
            <div class="header-box">
              <h1 style="font-weight: 600">{{ videoInfo.name }}</h1>
            </div>
            <div class="Title_item">
              <p class="member">会员专享</p>
              <p class="Type">{{ videoInfo.typeStr ? videoInfo.typeStr[0] : '' }}</p>
            </div>
            <div class="Infocontent">
              {{ videoInfo.text }}
            </div>
          </div>
          <div style="padding: 0px 20px">
            <video
              style="width: 100%; height: 650px"
              ref="video"
              :poster="videoInfo.videoImageUrl"
              :src="video.videoUrl"
              @timeupdate="myFunction"
              controls
            ></video>
          </div>
        </a-col>
      </a-row>
    </div>
    <div class="tab-box">
      <div
        :class="{
          'tab-item': true,
          'tab-item-active': index == tabIndex,
        }"
        v-for="(item, index) in tabList"
        @click="tabIndex = index"
        :key="item.value"
      >
        {{ item.name }}
      </div>
    </div>
    <div class="ml-box" v-show="tabIndex == 0">
      <div
        :class="{
          'ml-box-item': true,
          'ml-box-item-active': checkIndex === index,
        }"
        v-for="(item, index) in videoInfo.modVideosDetailVOList || []"
        :key="item.id"
        @click="handleChangeIndex(index)"
      >
        <img v-show="checkIndex === index" src="@/assets/home/bf.png" alt="" style="height: 28px; margin-right: 8px" />
        {{ item.name }}
      </div>
    </div>
    <div v-show="tabIndex == 1" class="content_list video-list" style="height: 500px; padding: 0 24px">
      <v-card-list
        ref="cardList"
        :getDataApi="getDataApi"
        :renderItem="renderItem"
        :initPagination="pagination"
        :colConfig="{
          span: 24,
        }"
      >
        <a-empty class="empty" slot="empty" />
      </v-card-list>
    </div>
    <a-modal title="试看结束提醒" :visible="promptFlag" width="1050px" @cancel="promptFlag = false">
      <template #footer></template>
      <div class="prompt-text">
        当前视频可免费观看<font color="red">15S</font> ，如需观看全部内容，建议您使用企业账号登录后查看。若您当前没有
        企业账号，也可以通过扫描下方二维码，下载“有好活APP”,注册登录成功后，观看全部内容。
      </div>
      <div class="model-img"><img src="@/assets/home/app-down.png" alt="" /></div>
      <div class="model-text">使用手机扫一扫下载安装有好活app</div>
      <div class="model-prompt">*ios用户可在app store 搜索安装应用</div>
    </a-modal>
  </div>
</template>

<script>
import commentCard from '@/components/commentCard.vue'
import { getVideoListById, getVideoPLList } from '@/api/video'
import moment from 'moment'
import defaultUser from '@/assets/home/default.png'
export default {
  components: {
    commentCard
  },
  data () {
    return {
      promptFlag: false, // 友情提示状态值
      videoInfo: {},
      videoId: '',
      checkIndex: 0,
      tabIndex: 0,
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0,
        showQuickJumper: true,
        showSizeChanger: false,
        showTotal: (total) => `总数 ${total} 条`
      },
      total: 0
    }
  },
  computed: {
    video () {
      const videoList = this.videoInfo.modVideosDetailVOList || []
      return videoList.length ? videoList[this.checkIndex] : {}
    },
    tabList () {
      const videoList = this.videoInfo.modVideosDetailVOList || []
      return [
        {
          name: `目录（${videoList.length}）`,
          value: '1'
        },
        {
          name: `评论（${this.total}）`,
          value: '2'
        }
      ]
    }
  },
  created () {
    this.videoId = this.$route.query.id
    getVideoListById({
      id: this.videoId
    }).then((res) => {
      this.videoInfo = res.data
    })
  },
  methods: {
    handleChangeIndex (index) {
      this.checkIndex = index
      this.$nextTick(() => {
        this.$refs.video.play()
      })
    },
    myFunction (event) {
      if (event.target.currentTime > 15 && !this._isVip) {
        this.$refs.video.pause()
        this.$refs.video.currentTime = 15
        this.promptFlag = true
      }
    },
    getDataApi (params) {
      return new Promise((resolve) => {
        getVideoPLList({
          ...params,
          videoId: this.videoId
        }).then((res) => {
          this.total = res.totalCount
          resolve(res)
        })
      })
    },
    renderItem ({ item }) {
      return (
        <div class="list-item">
          <div class="list-item-top">
            <img class="user-img" src={item.user.imgPath || defaultUser} />
            <span class="user-name">{item.user.realName || `未实名用户${item.user.username.substr(7)}`}</span>
            <span class="user-time">{moment(item.createTime).format('YYYY-MM-DD HH:mm:ss')}</span>
          </div>
          <div class="user-content">{item.content}</div>
        </div>
      )
    }
  }
}
</script>
<style lang="less">
@import url('../../common/style');
</style>
<style scoped lang="less">
.bidding__wrapper {
  margin: 30px;
  .tab-box {
    margin-top: 40px;
    height: 65px;
    padding: 0 62px;
    display: flex;
    align-items: center;
    position: relative;
    &::after {
      content: '';
      height: 20px;
      width: 105%;
      position: absolute;
      top: -20px;
      left: -30px;
      display: block;
      background: #f5f4f8;
    }
    .tab-item {
      font-size: 24px;
      color: #3d3d3d;
      font-weight: bold;
      margin-right: 92px;
      cursor: pointer;
      &-active {
        color: #1677ff;
      }
    }
  }
  .ml-box {
    padding: 6px 0;
  }
  .ml-box-item {
    font-size: 18px;
    color: #333333;
    padding: 6px 0;
    display: flex;
    align-items: center;
    cursor: pointer;
    &-active {
      color: #1677ff;
    }
  }
  .list-item-top {
    display: flex;
    align-items: center;
  }
  .user-img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 12px;
  }

  .user-name {
    flex: 1;
    display: inline-block;
    font-size: 16px;
    font-weight: bold;
  }
  .user-content {
    padding: 8px 0;
  }

  .header-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .info {
    height: 100%;
    position: relative;

    .popup {
      position: relative;
      padding: 60px;
      text-align: center;

      image {
        position: absolute;
        top: -100px;
        left: 60px;
      }

      h4 {
        margin: 150px 0 40px 0;
      }

      p {
        color: #7d807f;
        font-size: 26px;
      }

      .button {
        display: inline-block;
        text-align: center;
        margin-top: 60px;
      }
    }

    .Title {
      padding: 20px;

      .Title_item {
        .member {
          color: #fff;
          background: #4d72f7;
          display: inline-block;
          text-align: center;
          font-size: 13px;
          margin: 0px 20px 0px 0px;
          padding: 6px;
        }

        .Type {
          font-size: 13px;
          padding: 6px;
          background: rgba(77, 114, 247, 0.1);
          color: #1677ff;
          display: inline-block;
        }
      }

      .Infocontent {
        font-size: 16px;
        color: #aaaaaa;
        width: 100%;
      }
    }
    .type_item {
      display: flex;
      text-align: center;
      img {
        margin-top: 10px;
        cursor: pointer;
      }
      &_title {
        display: inline-block;
        p {
          font-size: 14px;
          margin-bottom: 0px;
        }
      }
    }
    .comment_item {
      height: 700px;
      overflow: auto;
      padding-bottom: 60px;
    }
  }
}
.prompt-text {
  width: 100%;
  text-align: center;
  font-size: 18px;
  margin: 0 auto;
  color: #000000;
}
.ant-modal-title {
  text-align: center;
  font-weight: 500;
  font-size: 18px;
  color: #000000;
}
.model-img {
  width: 220px;
  height: 220px;
  margin: 30px auto;
  img {
    width: 100%;
    height: 100%;
  }
}
.model-text {
  width: 100%;
  text-align: center;
  font-size: 18px;
  margin: 0 auto;
}
.model-prompt {
  width: 210px;
  height: 22px;
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  margin: 4px auto;
  margin-bottom: 30px;
}
</style>
