var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bidding__wrapper"},[_c('div',{staticClass:"commom_title"},[_c('a-row',[_c('a-col',{attrs:{"span":16}},[_c('a-row',[_c('a-col',{attrs:{"span":1}},[_c('div',{staticClass:"commom_line"})]),_c('a-col',{attrs:{"span":6}},[_vm._v("视频")])],1)],1)],1)],1),_c('div',{staticClass:"info"},[_c('a-row',[_c('a-col',{attrs:{"span":24}},[_c('div',{staticClass:"Title"},[_c('div',{staticClass:"header-box"},[_c('h1',{staticStyle:{"font-weight":"600"}},[_vm._v(_vm._s(_vm.videoInfo.name))])]),_c('div',{staticClass:"Title_item"},[_c('p',{staticClass:"member"},[_vm._v("会员专享")]),_c('p',{staticClass:"Type"},[_vm._v(_vm._s(_vm.videoInfo.typeStr ? _vm.videoInfo.typeStr[0] : ''))])]),_c('div',{staticClass:"Infocontent"},[_vm._v(" "+_vm._s(_vm.videoInfo.text)+" ")])]),_c('div',{staticStyle:{"padding":"0px 20px"}},[_c('video',{ref:"video",staticStyle:{"width":"100%","height":"650px"},attrs:{"poster":_vm.videoInfo.videoImageUrl,"src":_vm.video.videoUrl,"controls":""},on:{"timeupdate":_vm.myFunction}})])])],1)],1),_c('div',{staticClass:"tab-box"},_vm._l((_vm.tabList),function(item,index){return _c('div',{key:item.value,class:{
        'tab-item': true,
        'tab-item-active': index == _vm.tabIndex,
      },on:{"click":function($event){_vm.tabIndex = index}}},[_vm._v(" "+_vm._s(item.name)+" ")])}),0),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.tabIndex == 0),expression:"tabIndex == 0"}],staticClass:"ml-box"},_vm._l((_vm.videoInfo.modVideosDetailVOList || []),function(item,index){return _c('div',{key:item.id,class:{
        'ml-box-item': true,
        'ml-box-item-active': _vm.checkIndex === index,
      },on:{"click":function($event){return _vm.handleChangeIndex(index)}}},[_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.checkIndex === index),expression:"checkIndex === index"}],staticStyle:{"height":"28px","margin-right":"8px"},attrs:{"src":require("@/assets/home/bf.png"),"alt":""}}),_vm._v(" "+_vm._s(item.name)+" ")])}),0),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.tabIndex == 1),expression:"tabIndex == 1"}],staticClass:"content_list video-list",staticStyle:{"height":"500px","padding":"0 24px"}},[_c('v-card-list',{ref:"cardList",attrs:{"getDataApi":_vm.getDataApi,"renderItem":_vm.renderItem,"initPagination":_vm.pagination,"colConfig":{
        span: 24,
      }}},[_c('a-empty',{staticClass:"empty",attrs:{"slot":"empty"},slot:"empty"})],1)],1),_c('a-modal',{attrs:{"title":"试看结束提醒","visible":_vm.promptFlag,"width":"1050px"},on:{"cancel":function($event){_vm.promptFlag = false}},scopedSlots:_vm._u([{key:"footer",fn:function(){return undefined},proxy:true}])},[_c('div',{staticClass:"prompt-text"},[_vm._v(" 当前视频可免费观看"),_c('font',{attrs:{"color":"red"}},[_vm._v("15S")]),_vm._v(" ，如需观看全部内容，建议您使用企业账号登录后查看。若您当前没有 企业账号，也可以通过扫描下方二维码，下载“有好活APP”,注册登录成功后，观看全部内容。 ")],1),_c('div',{staticClass:"model-img"},[_c('img',{attrs:{"src":require("@/assets/home/app-down.png"),"alt":""}})]),_c('div',{staticClass:"model-text"},[_vm._v("使用手机扫一扫下载安装有好活app")]),_c('div',{staticClass:"model-prompt"},[_vm._v("*ios用户可在app store 搜索安装应用")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }